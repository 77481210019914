.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}

.forminput label {
  width: 100%;
  margin-bottom: 0px !important;
}

.centered {
  align-items: center;
  justify-content: center;
}

.durationBlock b {
  font-size: '16px'
}

.forminput .input-group {
  width: 100%;
}

.hideReports {
  display: none;
}

.forminput .react-datepicker-wrapper {
  display: grid;
  width: 100%;
}

.topbarmenu li {
  float: left;
  list-style: none;
}

.enlarged #wrapper .content-page {
  margin-left: 0px !important;
}

.left, .side-menu, .custom-left-side {
  display: none !important;
}

/* file-upload */
.dropzone {
  position: relative;
  text-align: center;
}

.dz-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.dropzone .dz-message:focus {
  outline: 1px solid transparent !important;
}

.badgtab {
  width: 25% !important;
  text-align: left !important;
  border-radius: 5px !important;
}

/* stachbarchart -> chartist */
.stackbarchart .ct-bar {
  stroke-width: 30px;
}

.piechart .ct-chart .ct-label {
  color: white;
  fill: white;
  font-size: 16px;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.toolbarClassName {
  background: #edeff1;
  border-bottom: 1px solid #e9ecef;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rdw-option-wrapper {
  background-color: transparent;
}

.rdw-editor-main {
  padding: 10px;
}

/* Dropdown */
.splitbtn .dropdown-toggle {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.splitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.dropsplitbtn .dropdown-toggle {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropsplitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.smsplitbtn .dropdown-toggle {
  padding-right: 0.372rem;
  padding-left: 0.372rem;
}

.smsplitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.ltsplitbtn .dropdown-toggle {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.ltsplitbtn .dropdown-toggle::before {
  margin-right: 0 !important;
}

@media screen and (max-width: 426px) and (min-width: 320px) {
  .sweet-alert {
    width: 95% !important;
    margin: 0;
    right: 0;
    left: 8px !important;
  }
}

.sweet-alert {
  margin-top: -161px !important;
  /* width: 32em !important; */
}

.sweet-alert h2 {
  font-size: 24px !important;
}

/* form-editors */
.wrapperClassName {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}

.editorClassName,
.ql-editor {
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ql-toolbar.ql-snow {
  background: #edeff1;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}

.DraftEditor-root {
  height: auto;
}

/* file Xeditable */
.editable-container a {
  border-bottom: dashed 1px #0088cc;
}

/* extra-pages (coming soon) */
.coming-box {
  border: none !important;
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44) !important;
  margin-bottom: 30px !important;
  margin: 0 15px;
  padding: 16px 16px 0 16px;
  background-color: white;
}

.coming-watch div {
  font-size: 40px !important;
  color: #626ed4 !important;
  font-weight: 500;
}

.coming-watch div span:first-child {
  height: 50px !important;
}

.dlpd-root {
  width: 100% !important;
  height: 360px !important;
  font-size: 11px !important;
}

.dlpd-root .initial-view .display input {
  width: 100% !important;
  margin-left: 2% !important;
}

.dlpd-root .initial-view .keypad {
  margin-top: 5px;
}

.coming-watch div span:last-child {
  font-size: 12px !important;
  color: #5b626b;
  text-transform: uppercase;
}

/* email-template */
.email-template .content {
  max-width: 570px !important;
}

/* create btn */
.btn-light {
  color: #212529 !important;
}

/* dashboard */
.dash-chart .chat-avatar {
  margin: 0 8px;
}

/* rating */
.rating-container {
  min-width: auto !important;
}

/* switch */
@media screen and (max-width: 426px) and (min-width: 320px) {
  .react-switch-bg {
    margin-bottom: 8px !important;
  }
}

/* Form wizard */
@media screen and (max-width: 426px) and (min-width: 320px) {
  .form-wizard .badgtab {
    width: 100% !important;
  }

  .form-wizard .col-form-label {
    text-align: left;
  }

}


.coming-watch div span:last-child {
  padding-top: 0px;
}

.react-datepicker-popper {
  z-index: 99;
}

.firstForm {
  display: none;
}

.boxmargin {
  margin-bottom: 20px !important;
  margin-top: 10px !important;

  font-size: 15px;
  height: 30px;
}

@media screen and (max-width: 426px) and (min-width: 320px) {
  .coming-soon-search-form {
    margin-bottom: 24px;
  }
}

/* #D52B1E */
/* #D52B1E */
.side-menu {
  background: #D52B1E !important;
}

.btn-info {
  background-color: #D52B1E !important;
  border: 1px solid#D52B1E !important;
}

.toggleclass {
  display: none;
}

.popupForm input {
  width: 31% !important;
  float: left;
}

.durationform input {
  width: 98%;
  float: left;
  margin-bottom: 5%;
  margin-left: 1%;
}

.transferForm input {
  width: 97% !important;
  float: left;
}

.table th, .table td {
  border-right: 1px solid #dee2e6;
}

.selectCompany {
  cursor: pointer;
}

.table th {
  color: white;
}

.table {
  width: 94%;
  margin-bottom: 1rem;
  color: #212529;
  margin-left: 3%;
  margin-right: 3%;
  border-bottom: 1px;
}

.card-header {
  background: #747678;
  color: white;
  text-align: center;
  border-radius: 20px 20px 0px 0px !important;
}

.rc-time-picker-clear-icon:after {
  font-style: normal;
  background-color: #216ba5 !important;
  color: #fff !important;
  border-radius: 50% !important;
  padding: 4px !important;
  font-size: 12px !important;
  line-height: 1 !important;
  display: table-cell !important;
  content: "\00d7" !important;
  height: 0px !important;
  width: 0px !important;
}

.rc-time-picker-clear {
  height: 100% !important;
  top: 6px !important;
}

.transferincident {
  background: #747678;
  color: white;
  text-align: unset !important
}

.transferincident li {
  float: left;
}

.transferincident b {
  font-size: 16px;
}

.historyTranfer {
  margin-bottom: 10px;
  height: 50px;
  width: 33%;
  float: left;
  cursor: pointer;
  border-radius: 20px 0px 0px !important;
}

.callQueueHeader {
  height: 50px;
  width: 76%;
  float: left;
  border-radius: 20px 0px 0px !important;
}

.cardSection {
  height: 670px;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
}

.plusIcns {
  border: 1px solid;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  margin-left: -11px;
}

.historySection {
  height: 620px;
  width: 100%;
  background-color: #fff
}

.historyTable {
  background-color: #747678;
  text-align: center
}

.contactImg {
  width: 20px;
  margin-right: 10px
}

.callQueueHeader li {
  list-style: none;
}

.historyTranfer2 {
  margin-bottom: 10px;
  height: 50px;
  width: 33%;
  float: left;
  cursor: pointer;
  border-radius: 0px !important;
}

.callQueueHeaderplus {
  height: 50px;
  width: 24%;
  float: left;
  border-radius: 0px 20px 0px 0px !important;
}

.callQueueHeaderplus li {
  list-style: none;
  width: 100%;
}

.labelshadow {
  margin-left: 13px;
}

.marginTopClass {
  margin-top: 18px;
}

.historyTranferHis {
  margin-bottom: 10px;
  height: 50px;
  width: 34%;
  float: left;
  cursor: pointer;
  border-radius: 0px 20px 0px 0px !important;
}

.history li {
  width: 100%;
  list-style: none;
  height: 25px;
  text-align: center;
  float: left;
}

.transferSelected b {
  font-size: 16px;
}

.transferSelected {
  background-color: #D52B1E !important;
}

.selectedButton {
  background-color: #7ECA62 !important;
}

.headercallqueue li {
  list-style: none;
}

.historyTranfernotShow {
  display: none;
}

.callqueue li {
  list-style: none;
  margin-left: 13px;
  margin-top: 1px;
}

.contactList li {
  list-style: none;
  margin-top: 5px;
  margin-left: 29px;
  margin-right: 22px;
}

.jobDetails li {
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
  list-style: none;
  color: black;
}

.cal {
  width: 100%;
}

.popHeader {
  margin-bottom: 10px;
  background-color: #de2c23;
  text-align: left
}

.notFound {
  text-align: center;
  font-size: 20px;
  color: red
}

.jobDetails-map {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.callIcons {
  height: 60px;
  padding: 0px 10px;
}

.callIcons li {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 18px;
  float: left;
  list-style: none;
  width: 45px;
  height: 45px;
  color: #3c3c4a;
  border: 3px solid #3e3e4c;
  border-radius: 100%;
  background-color: white;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.isIconSelectd {
  background-color: #3c3c4a !important;
  color: white !important;
}

.iconDefaultSelected {
  color: white !important;
  border: none !important;
  /* background: url('https://api.iconify.design/fluent-call-end-20-filled.svg?color=%23fff') no-repeat center center / contain; */
  /* background: url('https://api.iconify.design/mdi:phone-hangup.svg') no-repeat center center / contain; */
}

.css-2b097c-container {
  font-weight: normal !important;
}

.css-26l3qy-menu {
  font-weight: normal !important;
  z-index: 111111 !important;
}

.callqueuedetails {
  border-left: 10px solid #13c105 !important;
  border-right: 10px solid #13c105 !important;
  font-weight: 500;
  font-size: 15px;
}

.callInQueue {
  border-left: 10px solid #d9df44 !important;
  border-right: 10px solid #d9df44 !important;
  font-weight: 500;
  font-size: 15px;
}

.jobDetailSection {
  height: 620px;
  background-color: #fff;
}

.popupCompanyName {
  margin-left: 23px
}

.popupjob {
  margin-left: 4px
}

.popupCity {
  margin-left: 4px
}

.callInQueue.select {
  background-color: #f2f4c1;
}

.duratoindetails {
  font-weight: bold;
  margin-top: 5px;
  list-style: none;
  color: black;
  align-items: center;
  justify-content: center;
  padding: 10px;

}

.-form-duration {
  padding: unset;
}

.buttonradius {
  border-radius: 0px 0px 10px 10px;
  background-color: #9A9C9D;
  width: 100%;
}

.popupSection {
  background-color: #E1E2E0;
  margin-bottom: -20px;
}

.durationCardSection {
  height: 620px;
  background-color: #fff;
  margin-bottom: 0px;
  align-items: center;
}

.contactsTable {
  justify-content: center;
  align-items: center;
  width: 100%
}

.contactsLoader {
  text-align: center;
  font-size: 16px;
}

#overlay {
  pointer-events: all;
  z-index: 99999;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
}

.pagination .active a {
  background-color: #747678 !important;
  color: black
}

#overlay svg {
  margin-top: 225px !important;
}

.loaderClass {
  text-align: center;
  z-index: -999;
}

.modal-content {
  border-radius: 20px 20px 0px 0px !important;
  border-color: #868686;
  background-color: #E1E2E0;
}

.searchbutton {
  float: right;
  z-index: -999;
}

.tarnsferForm input {
  width: 70% !important;
}

.tarnsferForm label {
  color: #c7c6c2;
  margin-left: 30px;
  margin-bottom: 0px !important;
}

.form-group label {
  color: black;
  margin-left: 2px;
  margin-bottom: 0px !important;
}

.duratoindetails li {
  margin-top: 7px;
  text-align: center;
  /* font-size: 15px; */
  align-self: flex-start;
}

.selectQueue {
  background-color: #b1ebad;
  border-bottom: 1px solid #13c105;
}

.callQueueSection {
  cursor: 'pointer'
}

.jTime {
  width: 100% !important;
}

.jTime-clockInput {
  height: 35px !important;
}

.jTime-clockIcon {
  padding: 7px 4px !important;
  background: #b1ebad !important;
}

.IscallQueue {
  background-color: #f2f4c1;
  border-bottom: 1px solid hsl(66, 98%, 52%);
}

.selectEmp {
  background-color: #b1ebad;
  border-right: 10px solid #13c105 !important;
}

.selectEmpJob {
  background-color: #b1ebad;
  border-right: 10px solid #13c105 !important;
  border-left: 10px solid #13c105 !important;
}

.-call-hold-queue-list {
  background-color: #c4c4c8;
  border-right: 10px solid #3c3c4a !important;
  border-left: 10px solid #3c3c4a !important;
}

.card-header-active {
  background: #747678;
  color: white;
}

.queuehide {
  display: none;
}

.hideDuration {
  display: none;
}

.callqueue {
  color: black;

}

#sidebar-menu>ul>li>a.mm-active {
  color: white !important;
}

.enlarged #wrapper .navbar-custom {
  margin-left: 0px !important;
}

.enlarged #wrapper .topbar .topbar-left {
  margin-left: 0px !important;
}

.form-search {
  margin-left: 1%;
  width: 48%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#sidebar-menu>ul>li>a {
  color: white !important;
}

#sidebar-menu>ul>li>a.mm-active {
  color: #fff !important;
}

.submenu li.mm-active>a {
  color: #fff;
  background-color: transparent;
}

.submenu li a:hover {
  background-color: transparent;
}

.bg-color1 {
  background-color: #7001b0 !important;
}

.bg-color2 {
  background-color: #D52B1E !important;
}

.bg-color3 {
  background-color: #0136b0 !important;
}

.bg-color4 {
  background-color: #D52B1E !important;
}

.recharts-cartesian-grid-vertical {
  display: none !important;
}

.wrapper-page .logo-admin {
  border-bottom: 2px solid #626ed4 !important;
}

.search-input-matrial {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #495057;
  background-color: transparent;
}

.isDisabled {
  background-color: #c7c6c2;
}

.select-input-matrial {
  /* border: 0; */
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #495057;
  background-color: transparent;
}

.tabs-titles {
  list-style: none;
  padding: 0px;
  margin: 0;
}

.tab-title {
  background-color: #fff;
  display: inline-block;
  padding: 15px;
  color: #c7c6c2;
  cursor: pointer;
  margin-left: 1px;
}

.tab-title--active {
  background-color: #f5f5f5;
  color: #00070a;
}

.active {
  background-color: #D52B1E;
  color: white;
}



.table .wrap-container {
  justify-content: left;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #747678 !important;
  border-color: #007bff;
}

.top-main-logo {
  padding-top: 8px;
}

.custom-left-side {
  margin-top: 10px;
}

.bg-blue {
  background-color: #D52B1E !important;
}

.bg-blue .text-white-50 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.btn-primary {
  background-color: #747678;
  border: none !important;
  width: 100%;
}

.btn-primary:hover {
  background-color: #747678;
  border: 1px solid #747678;
}

a:hover {
  color: #D52B1E;
}

.button-menu-mobile {
  height: 59px !important;
  color: white !important;
}

.possition:after {
  font-family: 'FontAwesome';
  color: red;
  position: relative;
  right: -5px;
  content: "\f007";
}

.blue-primary {
  color: #D52B1E !important;
}

.user-list a {
  color: #D52B1E;
}

.common-data-list a {
  color: #D52B1E;
}

.formdata {
  padding-bottom: 20px;
  border: none;
  box-shadow: 0px 0px 13px 0px #e9ecef;
}

.user-img-thumb {
  text-align: center;
}

.user-img-thumb .btn {
  width: 68%;
}

.chart-filter-input {
  max-width: 400px;
  display: block;
  float: right;
}

.nav-justified .nav-item {
  cursor: pointer;
}

.rating .wrap-container {
  justify-content: left;
}

.exprt-btn {
  padding: 2px 5px;
  color: #fff !important;
}

.chat-user-role {
  font-size: 11px;
  margin-left: 4px;
  color: #5b626b;
  font-weight: normal;
}

.pac-container {
  z-index: 2001;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}

.inputcheckWidth {
  float: right;
  width: 65%;
  padding-top: '2px';
}

.checklabels {
  padding-bottom: 10px;
}

.boxshadowCard {
  width: 100%;
  padding-bottom: 20px;
  box-shadow: 0px 0px 13px 0px rgb(188 188 202 / 44%);
}

.labelHeading {
  color: black;
  /* width: 30%; */
}

.labels {
  padding-bottom: 20px;
}

@media (max-width: 480px) {
  .button-menu-mobile {
    display: none !important;
  }

  .duratoindetails li {
    font-size: 20px;
  }

  .card {
    max-width: 100% !important;
    padding-top: 5px;
  }

  .duratoindetails {
    padding: 0px;
  }

  .callQueueHeader {
    width: 76% !important;
  }

  .labels label {
    width: 100% !important;
  }

  .labels input {
    width: 98%;
  }

  .labelshadow {
    margin-left: 5px;
  }

  .checklabels label {
    width: 80% !important;
  }

  .inputcheckWidth {
    width: 20% !important;
    float: right;
  }

  #sidebar-menu {
    display: none;
  }
}

.-box-column {
  background-color: #fff;
  border-radius: 20px 20px 15px 15px;
  height: 100%;
}

.inputform table {
  color: black !important;
  background-color: #c4c4c8 !important;
}

.rdtSwitch {
  display: none !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
  height: 35px !important;
}

.checkboxTime input {
  height: 37px !important;
  font-size: 15px !important;
}

.timeInput input {
  height: 37px !important;
  font-size: 15px !important;
}

.rc-time-picker {
  width: 100% !important;
}

.react-datepicker-wrapper input {
  height: 35px !important;
  width: 100% !important;
}

.react-date-picker__inputGroup__input {
  height: 0px !important;
}

.dlpd-root .initial-view .keypad {
  height: 67% !important;
}

.dlpd-root .initial-view .display {
  height: 13% !important;
  background-color: #2D2D2D;
}

.dialPad {
  bottom: 0;
  width: 100%;
  margin-top: 235px;
}

.-duration-header {
  max-height: 50px;
}

.-duration-header.-select {
  background-color: #d9df44;
}

.-duration-header.-progress {
  background-color: #13c105;
}

.-duration-header.-hold {
  background-color: #3c3c4a;
}

.transferSection {
  height: 620px;
  width: 100%;
  background-color: #fff
}

.incidentFormSection {
  height: 650px;
  width: 100%;
  background-color: white;
  padding: 30px;
  overflow-y: auto;
  padding-left: 40px
}

.-call-queue-list {
  border-left: 10px solid #d9df44;
  border-right: 10px solid #d9df44;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 1px;
  /* border-bottom: 2px solid black; */
}

.contactLi {
  color: black;
  cursor: pointer
}

.-call-queue-list.-select {
  background-color: #f2f4c1;
}

.-call-queue-list.-progress {
  border-left: 10px solid #13c105;
  border-right: 10px solid #13c105;
}

.-call-queue-list.-hold {
  border-left: 10px solid #3c3c4a;
  border-right: 10px solid #3c3c4a;
}

.-call-queue-list.-progress.-select {
  background-color: #b1ebad;
}

.-call-queue-list.-hold.-select {
  background-color: #929296;
}

.-call-btn {
  background-color: #f1b9b5 !important;
  color: white !important;
  border: none !important;
  background: url('https://api.iconify.design/fluent-call-end-20-filled.svg?color=%23fff') no-repeat center center / contain;
}

.-call-btn.-connect {
  background-color: #13c105 !important;
  background: url('https://api.iconify.design/fluent-call-end-20-filled.svg?color=%23fff&rotate=270deg') no-repeat center center / contain;
}

.-call-btn.-disconnect {
  background-color: #d52b1e !important;
  background: url('https://api.iconify.design/fluent-call-end-20-filled.svg?color=%23fff') no-repeat center center / contain;
}

.-hang-call {
  transform: rotate(314deg);
}

.-dynamic-form {
  height: '650px';
  width: '100%';
  background-color: 'white';
  padding: 30;
  overflow-y: 'auto';
  padding-left: 40;
}

.-dynamic-form .-submit {
  background-color: '#D52B1E';
  width: '98%';
  margin-top: 30;
}

.-child-input {
  width: 100%;
  box-shadow: 0px 0px 13px 0px rgb(188 188 202 / 44%);
  padding: 12px;
}

@media screen and (max-width: 1280px) and (min-width: 800px) {
  .dialPad {
    margin-left: 0px;
  }
}